<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        extension-height="10"
        color="grey darken-4"
        dark
      >
        <v-toolbar-title>Ownership Transfer</v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template #activator>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile
            v-for="subItem in item.items"
            :key="subItem.title"
            :href="subItem.link"
            target="_blank"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
      </v-list>
    </v-card>
  </panel>
</template>

<script>
import { createGetParams } from '@/rest'

const pageTitle = 'Ownership Transfer'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Downloads', disabled: false, to: '/downloads',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      items: [],
      title: pageTitle,
    }
  },

  created: function () {
    const params = createGetParams({})
    this.$rest.get('getOwnershipTransfer.php', params)
      .then((response) => {
        this.items = response.data
      }).catch(error => {
        console.log(error)
      })
  },
}
</script>
